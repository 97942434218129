<template>
  <van-search v-model="value" placeholder="搜索商品" @search="onSearch" @clear="onClear" />
  <van-tabbar v-model="active" :fixed="false" :before-change="onChangeTab">
    <van-tabbar-item name="tag">分类</van-tabbar-item>
    <van-tabbar-item name="new">新品</van-tabbar-item>
    <van-tabbar-item name="rec">推荐</van-tabbar-item>
    <van-tabbar-item name="hot">热销</van-tabbar-item>
    <!-- <van-tabbar-item name="ohter" :to="{ name: 'GrouponProduct'}">拼团活动</van-tabbar-item> -->
  </van-tabbar>
  <list v-if="sonRefresh" :sort="sort" :tag="tag" :title="title" :store-id="parseInt(storeId)" style="margin:5px 0 0 0;"/>
  <van-popup v-model:show="tagShow" position="left" :style="{ height: '100%', background: '#fff' }">
    <van-cell-group title="全部分类">
      <van-sidebar v-model="activeKey" @change="onChangeTag">
        <van-sidebar-item v-for="item in tags" :title="item.text" :key="item" />
      </van-sidebar>
    </van-cell-group>
  </van-popup>
  <tabbar active="product" />
</template>

<script>
import List from './components/List'
import Tabbar from '../../components/Tabbar'
import { toRefs, reactive, onMounted, nextTick } from 'vue'
import { post } from '@/util/request'
import Cookies from 'js-cookie'

export default {
  components: {
    List,
    Tabbar
  },
  setup () {
    const state = reactive({
      activeKey: 0,
      active: 'new',
      sort: 'productId desc',
      title: '',
      tags: [],
      tag: undefined,
      tagShow: false,
      sonRefresh: true,
      value: '',
      storeId: Cookies.get('storeId')
    })
    const onSearch = (val) => {
      console.log(val)
      state.title = val
      refreshGoodsList()
    }
    const onClear = () => {
      state.title = ''
      refreshGoodsList()
    }
    const onChangeTab = (val) => {
      if (val === 'new') {
        state.sort = 'productId desc'
      }
      if (val === 'rec') {
        state.sort = 'sort desc'
      }
      if (val === 'hot') {
        state.sort = 'sales desc'
      }
      if (val === 'tag') {
        state.tagShow = true
        return false
      }
      refreshGoodsList()
      return true
    }
    const onChangeTag = (index) => {
      state.tag = state.tags[index].value
      state.tagShow = false
      refreshGoodsList()
    }
    const refreshGoodsList = () => {
      state.sonRefresh = false
      nextTick(() => {
        state.sonRefresh = true
      })
    }
    const init = () => {
      post('/tag.list', {
        storeId: state.storeId
      }).then(res => {
        state.tags.push({
          value: undefined,
          text: '全部'
        })
        for (const item of res.data.content) {
          state.tags.push({
            value: item.tagId,
            text: item.name
          })
        }
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onChangeTab,
      onChangeTag,
      onSearch,
      onClear
    }
  }
}
</script>
