<template>
  <van-list
    v-model:loading="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="getData"
  >
    <van-grid :border="false" :column-num="2" :gutter="5" :center="false">
      <van-grid-item v-for="item in items" :key="item.productId" :to="{ name: 'ProductDetail', query: { productId: item.productId }}">
        <van-image :src="item.image + '?imageView2/1/w/400/h/400/q/100'" />
        <div style="background:#fff;">
          <p class="van-multi-ellipsis--l2" style="margin:7px 0;">
            <small>{{ item.name }}</small>
          </p>
          <div style="color:#ff5000;">
            <h6 style="display:inline;">￥</h6>
            <h4 style="display:inline;">{{ item.price }}</h4>
            <small style="float:right;color:#999;">已售 {{ item.sales + item.fictitiousSales }} 件</small>
          </div>
        </div>
      </van-grid-item>
    </van-grid>
  </van-list>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import { post } from '@/util/request'

export default {
  props: {
    sort: {
      type: String
    },
    tag: {
      type: Number
    },
    title: {
      type: String
    },
    storeId: {
      type: Number
    },
    participateReduction: {
      type: Boolean
    },
    onSale: {
      type: Boolean,
      default: true
    },
    sdf: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  setup (props) {
    const state = reactive({
      page: 1,
      finished: false,
      loading: false,
      items: []
    })
    const init = () => {
    }
    const getData = () => {
      // console.log(Cookies.get('store_id'))
      post('/product.list', {
        bizScope: 'GENERAL',
        pageNum: state.page,
        storeId: props.storeId,
        sort: props.sort,
        tagId: props.tag,
        name: props.title,
        onSale: props.onSale
      }).then(res => {
        state.items = [...state.items, ...res.data.content]
        state.loading = false
        state.finished = res.data.last
        state.page++
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      getData
    }
  }
}
</script>
